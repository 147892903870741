var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{ref:"dialog",attrs:{"color":"#f4813f","return-value":_vm.todate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.todate=$event},"update:return-value":function($event){_vm.todate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-8",staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","persistent-hint":"","readonly":"","label":"Select  Date","color":"#f4813f","append-icon":"mdi-calendar"},on:{"click:append":function($event){_vm.todate ? _vm.gettimecal() : _vm.gettimecal()}},model:{value:(_vm.dataFormatted),callback:function ($$v) {_vm.dataFormatted=$$v},expression:"dataFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endmodal),callback:function ($$v) {_vm.endmodal=$$v},expression:"endmodal"}},[_c('v-date-picker',{attrs:{"min":_vm.minmonth,"max":new Date().toISOString().substr(0, 10),"color":"#f4813f","scrollable":""},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":function($event){_vm.endmodal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#f4813f"},on:{"click":[function($event){return _vm.filter_data()},function($event){return _vm.$refs.dialog.save(_vm.todate)}]}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow mt-n5",attrs:{"height":_vm.height,"dense":"","headers":_vm.tabledata,"items":_vm.exporttable,"items-per-page":10,"fixed-header":_vm.fixed,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No Data available. ")])]},proxy:true},{key:"item.swipe_time_stamp",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item).split(',')[1])}})]}},{key:"item.Actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item)?_c('v-icon',_vm._g({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.view_swipes_for_the_day(item)}}},on),[_vm._v("mdi-map-marker")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("View all Swipes")])])]}}],null,true)})],1)],1),_c('ViewProxyswipes',{attrs:{"viewProxyswipesDialog":_vm.viewProxyswipesDialog,"userDetailsObj":_vm.userDetailsObj},on:{"clicked":function($event){_vm.viewProxyswipesDialog = false}}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }