<template>
  <div>
    <v-dialog v-model="viewProxyswipesDialog" persistent width="500px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >Geo Location - {{ userDetailsObj.user_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>

      <v-card class="overflow--hidden">
        <GmapMap
          :center="
            swipeData[0] != undefined ? swipeData[0].position : defaultCenter
          "
          :zoom="10"
          map-type-id="terrain"
          style="width: 100%; height: 400px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in swipeData"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :title="' swipped at location : ' + m.location"
            @click="center = m.position"
            :icon="{ url: m.marker }"
          />
        </GmapMap>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    viewProxyswipesDialog: Boolean,
    userDetailsObj: Object,
  },

  data: () => ({
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    swipeData: [],
    geoSwipData: [],
  }),
  watch: {
    viewProxyswipesDialog: {
      handler() {
        {
          if (this.viewProxyswipesDialog == true) {
            this.swipeData = [];
            // console.log(this.swipeData);
            // console.log(this.userDetailsObj, "Userobject");

            let baseImage = "https://maps.google.com/mapfiles/ms/icons/";
            let markerImage = [
              "red-dot.png",
              // "yellow-dot.png",
              // "green-dot.png",
              // "blue-dot.png",
              // "orange-dot.png",
              // "purple-dot.png",
              // "pink-dot.png",
            ];
            this.geoSwipData.push({
              location: this.userDetailsObj.box_location,
              position: {
                lat: Number(this.userDetailsObj.user_lat),
                lng: Number(this.userDetailsObj.user_long),
              },
              marker:
                baseImage +
                markerImage[Math.floor(Math.random() * markerImage.length)],
            });
            this.swipeData = this.geoSwipData;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.swipeData.shift();
      this.swipeData = [];

      this.$emit("clicked", 0);
    },
  },
};
</script>
  
  <style></style>
  