<template>
  <div>
    <v-card flat class="mt-2">
      <v-toolbar flat>
        <v-dialog
          ref="dialog"
          v-model="endmodal"
          color="#f4813f"
          :return-value.sync="todate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataFormatted"
              dense
              outlined
              class="mt-8"
              persistent-hint
              readonly
              label="Select  Date"
              color="#f4813f"
              append-icon="mdi-calendar"
              style="max-width: 150px"
              @click:append="todate ? gettimecal() : gettimecal()"
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :min="minmonth"
            :max="new Date().toISOString().substr(0, 10)"
            v-model="todate"
            color="#f4813f"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="#f4813f" @click="endmodal = false">
              Cancel
            </v-btn>
            <v-btn
              v-on:click="filter_data()"
              text
              color="#f4813f"
              @click="$refs.dialog.save(todate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow mt-n5"
          dense
          :headers="tabledata"
          :items="exporttable"
          :items-per-page="10"
          :fixed-header="fixed"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Data available.
            </v-alert>
          </template>
          <template v-slot:[`item.swipe_time_stamp`]="{ item }">
            <span v-text="get_date(item).split(',')[1]"></span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="view_swipes_for_the_day(item)"
                  color="primary"
                  v-if="item"
                  small
                  v-on="on"
                  >mdi-map-marker</v-icon
                >
              </template>
              <span class="white--text">View all Swipes</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ViewProxyswipes
      :viewProxyswipesDialog="viewProxyswipesDialog"
      :userDetailsObj="userDetailsObj"
      @clicked="viewProxyswipesDialog = false"
    />
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import moment from "moment";
import Snackbar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { get_swipes_proxy_organisations } from "@/graphql/queries.js";
import ViewProxyswipes from "../../../../components/Dialogs/ViewProxyswipes.vue";
const { find } = require("geo-tz");
export default {
  components: {
    Overlay,
    ViewProxyswipes,
    Snackbar,
  },
  data: () => ({
    userDetailsObj: {},
    SnackBarComponent: {},
    fixed: true,
    endmodal: false,
    next_token: null,
    timezone: "",
    viewProxyswipesDialog: false,
    tabledata: [
      { text: "Member ID", fixed: true, value: "employee_id" },
      { text: "Member Name", fixed: true, value: "user_name" },
      { text: "Proxy By", fixed: true, value: "proxy_by" },
      { text: "Swiped Location", fixed: true, value: "box_location" },
      { text: "Swipe Time", fixed: true, value: "swipe_time_stamp" },

      // { text: "Proxy Message", fixed: true, value: "swipe_message" },

      {
        text: "Geo",
        fixed: true,
        value: "Actions",
      },
    ],
    overlay: false,
    exporttable: [],
    todate: new Date().toISOString().substr(0, 10),
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    dataFormatted() {
      return this.dateformating(this.todate);
    },
  },
  created() {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(this.timeZone);
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    this.get_daily_present_reports();
    this.height = window.innerHeight - 195;
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_date(date) {
      var timeZOne = find(Number(date.user_lat), Number(date.user_long))[0];
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleTimeString(
        "en-US",
        {
          timeZone: timeZOne,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
      return a.toUpperCase();
    },
    filter_data() {
      this.next_token = null;
      this.exporttable = [];
      this.get_daily_present_reports();
    },
    gettimecal() {
      this.endmodal = true;
    },
    dateformating(todate) {
      if (!todate) return null;
      const [year, month, day] = todate.split("-");
      return `${day}/${month}/${year}`;
    },
    view_swipes_for_the_day(item) {
      this.userDetailsObj = item;
      this.viewProxyswipesDialog = true;
    },
    async get_daily_present_reports() {
      this.isLoading = true;
      this.overlay = true;

      try {
        let result = await API.graphql(
          graphqlOperation(get_swipes_proxy_organisations, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,

              nextToken: this.next_token,
              limit: 100,
              start_time: new Date(this.todate).getTime() / 1000,
              end_time: new Date(this.todate).getTime() / 1000,
            },
          })
        );
        var res = JSON.parse(result.data.get_swipes_proxy_organisations);
        // console.log(JSON.parse(result.data.get_swipes_proxy_organisations));
        var response = JSON.parse(
          result.data.get_swipes_proxy_organisations
        ).data;

        this.next_token = JSON.parse(
          result.data.get_swipes_proxy_organisations
        ).nextToken;

        if (res.Status == "SUCCESS") {
          let array = this.exporttable.concat(response);
          this.exporttable = array;
          // console.log(this.exporttable);
          const uniqueArrayOfObjects = this.exporttable.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.swipe_id === obj.swipe_id)
          );
          this.exporttable = uniqueArrayOfObjects;
          // console.log(this.exporttable);
        } else {
          this.exporttable = [];
        }
        this.isLoading = false;
        this.overlay = false;
        // console.log(this.exporttable);
      } catch (err) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
</style>